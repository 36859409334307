import React from 'react';

import { styled } from 'styles';
import dexterAiImg from 'images/mvp/dexter-ai.png';

export function DexterAiProfile() {
  return (
    <Container>
      <Image src={dexterAiImg} />
      <Name>Dexter IA</Name>
    </Container>
  );
}

const Container = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-top: 40px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

const Name = styled.p`
  font-size: 0.7em;
  font-weight: normal;
  color: black;
  margin-top: 5px;
`;
