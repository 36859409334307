import React, { useCallback, useMemo } from 'react';
import { styled } from 'styles';
import Select from 'react-select';
import { useBookExerciseTracker } from 'modules/books/hooks';
import { useBookEditionContext } from '../../../providers/BookEditionProvider';
import useBookSelector from '../../../hooks/useBookSelector';
import { useTracker } from 'modules/shared/hooks';

export default function BookExerciseSelects({ onChange }) {
  const {
    setCurrentSecttionId,
    currentExerciseId,
    currentSectionId,
    onChangeExerciseSelected,
  } = useBookEditionContext();
  const { trackSectionClickOnSelector } = useBookExerciseTracker();
  const { exerciseOptions, sectionOptions } = useBookSelector();

  const { trackEvent } = useTracker();

  const onSectionSelect = useCallback(
    option => {
      trackSectionClickOnSelector(option.value);
      setCurrentSecttionId(option.value);
      if (currentExerciseId !== -1) onChangeExerciseSelected(-1);
    },
    [
      setCurrentSecttionId,
      currentExerciseId,
      onChangeExerciseSelected,
      trackSectionClickOnSelector,
    ]
  );

  const currentSectionValueInSelector = useMemo(() => {
    return sectionOptions.find(section => section.value === currentSectionId);
  }, [currentSectionId, sectionOptions]);

  function onChangeExercise(exerciseId) {
    onChangeExerciseSelected(exerciseId);

    trackEvent('Trial de Questão: Interagiu CTA Livros', {
      action: 'Trocou questão',
      contentId: currentExerciseId,
      contentType: 'BookExercise',
      contentUrl: window.location.href,
    });

    onChange && onChange();
  }

  return (
    <SelectContainer data-cy="selector-container" className="new">
      <Select
        value={currentSectionValueInSelector}
        options={sectionOptions}
        onChange={onSectionSelect}
        styles={customSelectStyle('Capítulo')}
        placeholder={'Escolha'}
      />

      {currentSectionId !== -1 && (
        <Select
          value={
            exerciseOptions.find(opt => opt.value === currentExerciseId) || null
          }
          options={exerciseOptions}
          onChange={option => onChangeExercise(option.value)}
          styles={customSelectStyle('Questão')}
          placeholder={'Escolha'}
        />
      )}
    </SelectContainer>
  );
}

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const customSelectStyle = placeholder => ({
  control: provided => ({
    ...provided,
    borderRadius: '10px',
    border: 'none',
    background: 'rgba(0,0,0,0.15)',
    boxShadow: 'none',
    padding: '0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.20)',
    },
  }),
  container: provided => ({
    ...provided,
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    color: 'white',
    margin: '0',
  }),
  option: (provided, { data }) => ({
    ...provided,
    color: '#14B9A6',
    margin: 'auto auto 5px auto',
    backgroundColor: 'rgba(0,0,0,.1)',
    width: '90%',
    padding: '3px',
    textAlign: 'center',
    '&::after': data.isFree && {
      content: `"GRÁTIS"`,
      fontSize: '12px',
      fontWeight: 'bold',
      paddingLeft: '3px',
      paddingRight: '3px',
      paddingTop: '2px',
      paddingBottom: '2px',
      color: 'white',
      backgroundColor: '#F7AC3B',
      display: 'block',
      width: '97%',
      margin: '2px auto 2px auto',
    },

    '&:hover': {
      color: '#FFF',
      backgroundColor: '#14B9A6',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.1em',
    '&::before': {
      content: `"${placeholder}: "`,
      fontWeight: 'normal',
      marginRight: '5px',
      fontSize: '.85em',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.1em',
    '&::before': {
      content: `"${placeholder}: "`,
      fontWeight: 'normal',
      marginRight: '5px',
      fontSize: '.85em',
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#FFF!important',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: '#FFF',
  }),
});
