import React, { useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Header } from 'modules/shared/components/header';
import profileRoutes from 'modules/profile/routes';
import summariesRoutes from 'modules/summaries/routes';
import mainRoutes from 'modules/main/routes';
import publicLecturesCampaignRoutes from 'modules/campaigns/icb-lecture/routes';
import lecturesRoutes from 'modules/lectures/routes';
import guidesRoutes from 'modules/guides/routes';
import workspaceRoutes from 'modules/workspace/routes';
import campaignLectureRoutes from 'modules/campaigns/campaign-lecture/routes';
import { useLocation } from 'react-router-dom';
import Footer from 'modules/shared/components/Footer';
import booksRoutes from 'modules/books/routes';
import bypassContentRoutes from 'modules/bypass-content/routes';
import mvpRoutes from 'modules/mvp/routes';
import searchedExercisesRoutes from 'modules/searched-exercises/routes';

export default function UnloggedRouter() {
  const history = useHistory();
  const location = useLocation();

  const shouldShowLoginContainerOnHeader = useMemo(() => {
    return !location.pathname.includes('/icb/aulao-intensivao');
  }, [location]);

  const shouldShowFooter = useMemo(() => {
    return (
      !location.pathname.includes('/icb/aulao-intensivao') &&
      !location.pathname.includes('/desafio') &&
      !location.pathname.includes('/aulao') &&
      !location.pathname.includes('/resolver-lista')
    );
  }, [location]);

  return (
    <>
      <Header shouldShowLoginContainer={shouldShowLoginContainerOnHeader} />
      <Switch>
        {profileRoutes}
        {mainRoutes}
        {publicLecturesCampaignRoutes}
        {summariesRoutes}
        {booksRoutes}
        {guidesRoutes}
        {workspaceRoutes}
        {lecturesRoutes}
        {campaignLectureRoutes}
        {bypassContentRoutes}
        {mvpRoutes}
        {searchedExercisesRoutes}
        <Route
          path="/planos"
          component={() => {
            window.location.href = `${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`;
            return null;
          }}
        />
        <Route
          path="/"
          component={() => {
            history.push('/materias');
            return null;
          }}
        />
      </Switch>
      <Footer shouldShowFooter={shouldShowFooter} />
    </>
  );
}
