import React, { useEffect } from 'react';
import { styled, media } from 'styles';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';

export default function ExerciseStatement({
  termSearched = '',
  solvedByAi,
  primaryColor,
}) {
  const {
    bookExercise,
    setNumViewsExerciseStatement,
  } = useBookEditionContext();

  useEffect(() => {
    setNumViewsExerciseStatement(prev => prev + 1);
  }, [setNumViewsExerciseStatement]);

  return (
    <Container>
      {termSearched !== '' && (
        <>
          <BodyHeader primaryColor={primaryColor}>
            Você pesquisou por:{' '}
          </BodyHeader>
          <div style={{ fontStyle: 'italic', marginBottom: '30px' }}>
            <MathjaxContentWithLatex
              text={termSearched}
              isLatex={!!solvedByAi}
            />
          </div>
        </>
      )}

      <BodyHeader primaryColor={primaryColor}>Enunciado </BodyHeader>
      <MathjaxContainer>
        <MathjaxContentWithLatex
          text={bookExercise?.lightBody}
          isLatex={!!solvedByAi}
        />
      </MathjaxContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 30px;

  p,
  li {
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const BodyHeader = styled.h2`
  color: ${({ primaryColor }) => primaryColor};
  font-size: 1.3em;
  font-weight: bold;
  margin: 20px 0 10px 0;
  padding-bottom: 3px;
  border-bottom: 2px solid ${({ primaryColor }) => primaryColor};
`;

const MathjaxContainer = styled.div`
  margin: 0 10px;
  font-style: italic;

  @media ${media.mobile} {
    margin: 0;
  }
`;
