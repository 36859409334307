import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMousePointer } from '@fortawesome/free-solid-svg-icons';

import booksImg from './assets/books.png';

export default function BooksCTA() {
  const onClick = useCallback(() => {
    window.open('/livros-resolvidos', '_blank');
  }, []);

  return (
    <Container onClick={onClick}>
      <Image src={booksImg} />
      <Title>
        ESCOLHA O LIVRO E A QUESTÃO <FontAwesomeIcon icon={faMousePointer} />
      </Title>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  color: #fff;
  background: #14b9a6;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
`;

const Title = styled.h1`
  font-size: 1.3em;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px 25px;
  width: fit-content;
  margin: auto;
  margin-top: 5px;
  border-radius: 10px;

  svg {
    font-size: 0.9em;
    margin-left: 5px;
  }
`;
