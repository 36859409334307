import React from 'react';
import { Route } from 'react-router-dom';

import { SearchedExercisePage } from './pages';

export default [
  <Route
    exact
    path="/exercicios-buscados/:exerciseId"
    component={SearchedExercisePage}
    key="exercicio-buscado"
  />,
];
