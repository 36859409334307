import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { media } from 'styles';

export function useTrackerSearchedExercisePage() {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const { exerciseId } = useParams();

  const defaultProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      ...getUTMsByLocation(window.location),
      'ID Exercicio': exerciseId,
    };
  }, [getUTMsByLocation, exerciseId]);

  const trackViewPage = useCallback(
    access => {
      trackEvent('Exercicios Buscados: Visualizou Pagina Exercicio', {
        ...defaultProperties,
        'Tem acesso?': access ? 'Sim' : 'Não',
      });
    },
    [trackEvent, defaultProperties]
  );

  return {
    trackViewPage,
  };
}
