import React from 'react';
import { styled } from 'styles';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import BookExercise from './BookExercise';
import BookExerciseSelector from './BookExerciseSelector';
import DesktopBookBanner from 'modules/banner/book-banner/DesktopBookBanner';
import MobileBookBanner from 'modules/banner/book-banner/MobileBookBanner';
import BookNavigationButtons from './BookNavigationButtons';
import { media } from 'styles';
import { useRenderBookEditionPageDialog } from 'modules/books/hooks';
import BookInfoInline from './lists/BookInfoInline';

export default function ContentContainer({
  shouldShowBookInfoInline,
  isOnSEOQuestionTrial,
}) {
  const { currentExerciseId } = useBookEditionContext();

  useRenderBookEditionPageDialog(isOnSEOQuestionTrial);

  return (
    <Container>
      {!isOnSEOQuestionTrial && (
        <>
          {!media.isMobile() && <DesktopBookBanner />}
          {media.isMobile() && <MobileBookBanner />}
          {shouldShowBookInfoInline && <BookInfoInline />}
          <BookExerciseSelector />
        </>
      )}

      {isOnSEOQuestionTrial &&
        (!currentExerciseId || currentExerciseId === -1) && (
          <NoExerciseSelectedMessage>
            Selecione uma questão de livro acima
            <br />
            ou envie sua lista de exercícios
          </NoExerciseSelectedMessage>
        )}

      {currentExerciseId !== -1 && (
        <>
          {!isOnSEOQuestionTrial && <BookNavigationButtons />}
          <BookExercise />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  max-width: 95%;
  padding: 20px 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0px auto 30px auto;

  @media ${media.mobile} {
    padding-top: 0;
    overflow: hidden;
  }
`;

const NoExerciseSelectedMessage = styled.p`
  color: #14b9a6;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 40px 0;
`;
