import { useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { buildFormData } from 'modules/shared/utils';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2`,
  withCredentials: true,
});

export default function useContentRestRequest() {
  const getOptionsRequest = useCallback(options => {
    const userJwt = Cookies.get('user_jwt');
    options.headers = {
      ...(options.headers || {}),
      'User-JWT': userJwt,
    };
    return options;
  }, []);

  const sendGetRequest = useCallback(
    async (url, options = {}) => {
      const optionsReq = getOptionsRequest(options);
      const response = await instance.get(url, optionsReq);
      return { data: response.data || {} };
    },
    [getOptionsRequest]
  );

  const sendPostRequest = useCallback(
    async (url, data = {}, options = {}) => {
      const optionsReq = getOptionsRequest(options);
      const response = await instance.post(url, data, optionsReq);
      return { data: response.data || {} };
    },
    [getOptionsRequest]
  );

  const sendPostStreamRequest = useCallback(
    async ({ url, options = {}, onStream, onEnd, onError }) => {
      const optionsReq = getOptionsRequest(options);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2${url}`,
          {
            method: 'POST',
            headers: optionsReq.headers,
          }
        );

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let done = false;
        while (!done) {
          const { done: newDone, value } = await reader.read();
          done = newDone;

          const chunk = decoder.decode(value, { stream: true });
          if (onStream) onStream(chunk);
        }

        if (response.status >= 400) {
          onError({ status: response.status });
          return;
        }

        onEnd();
      } catch (error) {
        onError(error);
      }
    },
    [getOptionsRequest]
  );

  const sendPutRequest = useCallback(
    async (url, data = {}, options = {}) => {
      const optionsReq = getOptionsRequest(options);
      const response = await instance.put(url, data, optionsReq);
      return { data: response.data || {} };
    },
    [getOptionsRequest]
  );

  const sendDeleteRequest = useCallback(
    async (url, options = {}) => {
      const optionsReq = getOptionsRequest(options);
      const response = await instance.delete(url, optionsReq);
      return { data: response.data || {} };
    },
    [getOptionsRequest]
  );

  const sendPostFileRequest = useCallback(
    async (url, file = {}, data = {}, options = {}) => {
      const userJwt = Cookies.get('user_jwt');

      options.headers = {
        ...(options.headers || {}),
        'User-JWT': userJwt,
        'Content-Type': 'multipart/form-data',
      };

      const formData = new FormData();
      if (file) formData.append('file', file);

      buildFormData(formData, data);

      try {
        const response = await instance.post(url, formData, options);
        return { data: response.data };
      } catch (e) {
        return { error: e.response.data };
      }
    },
    []
  );

  return {
    sendGetRequest,
    sendPostRequest,
    sendPostStreamRequest,
    sendPutRequest,
    sendDeleteRequest,
    sendPostFileRequest,
  };
}
