import React, { useCallback, useEffect, useRef } from 'react';
import { styled, colors } from 'styles';
import { MdOutlineUploadFile } from 'react-icons/md';
import UploadAreaListWithStatement from './UploadAreaListWithStatement';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';
import { useUserContext } from 'providers/UserProvider';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useToast } from 'modules/shared/hooks';

export function UploadListWithStatementStep() {
  const {
    trackViewSendListScren,
    trackClickSendListWithStatement,
    trackSendError,
  } = useListWithStatementTracker();
  const { user } = useUserContext();
  const { sendPostFileRequest, sendGetRequest } = useContentRestRequest();
  const { errorToast } = useToast();

  const {
    file,
    setFile,
    selectedTheme,
    professor,
    exam,
    setLoading,
    setStep,
    selectedDiscipline,
    setListLink,
    setFoundPercentage,
    setListThemeId,
  } = useUploadDialogContext();

  const pollTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (pollTimeoutRef.current) {
        clearTimeout(pollTimeoutRef.current);
      }
    };
  }, []);

  /**
   * Poll the list's status until success or fail.
   * - attempt = 0 => immediate poll, then wait 15s if still pending
   * - attempt > 0 => wait 5s if still pending
   */
  const pollListStatus = useCallback(
    async function pollListStatus(listId, attempt = 0) {
      try {
        const response = await sendGetRequest(
          `/list-with-statements/${listId}/status`
        );
        const data = response.data;

        if (data.status === 'success') {
          // If necessary, you can also GET more data for foundPercentage
          // or rely on the status endpoint if it returns it.
          // setFoundPercentage(data.foundPercentage ?? 0);

          setListLink(
            `${process.env.REACT_APP_REACT_URL}/lista-de-enunciado/${listId}`
          );
          setFoundPercentage(data.foundPercentage ?? 0);
          setListThemeId(data.themeId);
          setStep(4);
          setLoading(false);
        } else if (data.status === 'failed') {
          trackSendError('Erro ao processar lista');
          errorToast(data.failReason || 'Algo deu errado ao processar a lista');
          setStep(3);
          setLoading(false);
        } else {
          // Status is still pending
          const delay = attempt === 0 ? 15000 : 5000; // ms
          pollTimeoutRef.current = setTimeout(() => {
            pollListStatus(listId, attempt + 1);
          }, delay);
        }
      } catch (err) {
        console.log('Error response:', err);
        trackSendError('Erro ao processar lista');
        errorToast('Alguma coisa deu errado, tente novamente mais tarde');
        setStep(3);
        setLoading(false);
      }
    },
    [
      errorToast,
      setLoading,
      setStep,
      setListLink,
      // setFoundPercentage, // if needed
      sendGetRequest,
      trackSendError,
    ]
  );

  /**
   * Handles the initial upload request:
   * - If successful, start polling the new listId.
   */
  const sendListWithStatementRequest = useCallback(async () => {
    if (!file) {
      errorToast('Selecione um arquivo');
      return;
    }
    setLoading(true);
    setStep('loading');
    trackClickSendListWithStatement({
      fileName: file.name,
      fileType: file.type,
      professor,
      themeName: selectedTheme.name,
      exam,
    });

    try {
      // POST to /list-with-statements/upload
      const response = await sendPostFileRequest(
        '/list-with-statements/upload',
        file,
        {
          examName: exam,
          professorId: professor.id,
          professorName: professor.name,
          disciplineId: selectedDiscipline.id,
          disciplineModelName: selectedDiscipline.modelName,
        }
      );

      if (response.error) {
        trackSendError('Erro ao enviar lista');
        errorToast(
          response.error?.message ||
            'Alguma coisa deu errado, tente novamente mais tarde'
        );
        setStep(3);
        setLoading(false);
        return;
      }

      // Extract the listId from the successful upload response
      const { listId } = response.data;

      // Immediately poll for status (attempt = 0)
      pollListStatus(listId, 0);
    } catch (err) {
      console.log('Error response:', err);
      trackSendError('Erro ao enviar lista');
      errorToast('Alguma coisa deu errado, tente novamente mais tarde');
      setStep(3);
      setLoading(false);
    }
  }, [
    file,
    errorToast,
    setLoading,
    setStep,
    trackClickSendListWithStatement,
    professor,
    selectedTheme,
    exam,
    user,
    selectedDiscipline,
    trackSendError,
    sendPostFileRequest,
    pollListStatus,
  ]);

  useEffect(() => {
    trackViewSendListScren({
      themeName: selectedTheme.name,
      professor: professor.name,
      exam: exam,
    });
  }, [exam, professor, selectedTheme.name, trackViewSendListScren]);

  return (
    <Container>
      <Title>Agora é a hora de enviar sua lista!</Title>
      {file ? (
        <FileContainer>
          <MdOutlineUploadFile color={colors.darkGray} />
          <p>{file.name}</p>
        </FileContainer>
      ) : (
        <UploadAreaListWithStatement
          exam={exam}
          professor={professor}
          selectedTheme={selectedTheme}
          setFile={setFile}
        />
      )}
      <SendListButton onClick={sendListWithStatementRequest}>
        Enviar Lista
      </SendListButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  user-select: none;

  svg {
    font-size: 25px;
  }

  p {
    margin-left: 10px;
  }
`;

const SendListButton = styled.button`
  width: 90%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  padding: 10px 0px;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
