import React, { useMemo } from 'react';

import { useRenderDialog } from 'modules/shared/hooks';
import {
  useEmailInvolvement,
  EmailInvolvementDialogB,
} from 'modules/campaigns/email-involvement';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import SearchFeedbackDialog from 'modules/search/dialogs/SearchFeedbackDialog';
import useSearchFeedback from 'modules/search/hooks/useSearchFeedback';
import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';

export default function useRenderBookEditionPageDialog(isOnSEOQuestionTrial) {
  const { showEmailInvolvementDialog } = useEmailInvolvement();
  const { showBookCreditCardTrialCTA } = useBookTrialCampaign();
  const { shouldShowSearchFeedbackDialog } = useSearchFeedback();

  const dialogs = useMemo(() => {
    if (isOnSEOQuestionTrial) return [];

    return [
      {
        condition: showEmailInvolvementDialog,
        render: () => <EmailInvolvementDialogB />,
      },
      {
        condition: () => showBookCreditCardTrialCTA(),
        render: () => <BookCreditCardTrialCTADialog source={'Livro'} />,
      },
      {
        condition: () => shouldShowSearchFeedbackDialog(),
        render: () => (
          <SearchFeedbackDialog
            source="Questão Livro"
            text="Você encontrou o que estava procurando?"
          />
        ),
        timeToOpen: 10000,
      },
    ];
  }, [
    showEmailInvolvementDialog,
    showBookCreditCardTrialCTA,
    shouldShowSearchFeedbackDialog,
    isOnSEOQuestionTrial,
  ]);

  useRenderDialog(dialogs);
}
