import { Skeleton } from '@mui/material';
import { styled } from 'styles';

export const ExerciseSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    width: 960px;
    max-width: 95%;
    height: 500px;
    margin: auto;
  }
`;
