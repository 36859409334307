import React, { useCallback, useEffect } from 'react';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import {
  useContentQuery,
  useContentRestRequest,
} from 'modules/shared/hooks/requests';

import { media, styled } from 'styles';
import { Skeleton } from '@mui/material';
import { useTrackEvents } from 'modules/mvp/hooks';
import { Link } from 'react-router-dom';

export function SearchLogs({ question, onCheckResult }) {
  const { data: searchLogs } = useContentQuery(
    `/mvp/lists-with-statements/question-logs/${question.id}`
  );
  const { sendPostRequest } = useContentRestRequest();
  const { trackViewSearchOptions, trackSelectSearchOptions } = useTrackEvents();

  useEffect(() => {
    if (!searchLogs || !question) return;

    trackViewSearchOptions(question, question.listWithStatement);
  }, [searchLogs, question]);

  const onSearchLogClick = useCallback(
    async searchLog => {
      try {
        await sendPostRequest('/mvp/lists-with-statements/user-selections', {
          mvpListWithStatementQuestionId: question.id,
          exerciseType: searchLog.exerciseType,
          exerciseId: searchLog.exerciseId,
        });
        trackSelectSearchOptions(
          question,
          question.listWithStatement,
          searchLog
        );
        onCheckResult(true);
      } catch (e) {
        alert('Erro. Por favor, entre em contato com atendimento.');
      }
    },
    [sendPostRequest, question]
  );

  const searchLogsToShow = searchLogs
    ? searchLogs.filter(log => log.exercise) // avoid errors with exercise was deleted
    : [];

  return (
    <Container>
      <StatementTitle>Questão {question.label} da sua Lista</StatementTitle>
      <StatementContent>
        <Statement text={question.statement} isLatex={true} />
        {question.images.length > 0 && <Image src={question.images[0]} />}
      </StatementContent>

      <StatementTitle>
        Questões parecidas que encontramos no Responde Ai
      </StatementTitle>
      <Instructions>
        Selecione a opção que mais se parece com sua questão:
      </Instructions>

      {searchLogsToShow.map(searchLog => (
        <OptionContainer
          key={searchLog.id}
          onClick={() => onSearchLogClick(searchLog)}
        >
          <Statement text={searchLog.exercise.lightBody} />
        </OptionContainer>
      ))}

      {searchLogsToShow.length > 0 && (
        <NoOptionLink to={`/lista-de-enunciado/${question.listId}`}>
          Não encontrei nenhuma questão parecida <br />
          (Voltar para lista)
        </NoOptionLink>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
`;

const StatementTitle = styled.h1`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.4em;
  text-decoration: underline;
`;

const StatementContent = styled.div`
  display: flex;

  @media ${media.mobile} {
    flex-direction: column;

    img {
      margin: 0 auto 10px auto;
    }
  }
`;

const Statement = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
`;

const Image = styled.img`
  max-width: 150px;
  height: auto;
  object-fit: contain;
  margin-left: 20px;
`;

const Instructions = styled.h2`
  margin: 10px 0;
  color: #5d5d5d;
  font-size: 1.2em;
  font-weight: bold;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const NoOptionLink = styled(Link)`
  width: 350px;
  max-width: 95%;
  margin: auto;
  padding: 8px 0;
  background-color: #ed6d6d;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
`;
