import React from 'react';
import styled from 'styled-components';

import { MathpixMarkdownContent } from 'modules/shared/components/generic';
import { DexterAiProfile } from './DexterAiProfile';
import { NoAccessDisclaimer } from 'modules/shared/components/permission';
import { colors } from 'styles';

export function Exercise({ exercise, hasAccess }) {
  return (
    <Container>
      <DexterAiProfile />

      <Content>
        <Title>Enunciado</Title>
        <RenderContent text={exercise.body} hasAccess={true} />

        <Line />

        {exercise.solution.map((step, index) => (
          <div key={index}>
            <Title>Passo {index + 1}</Title>
            <RenderContent text={step} hasAccess={hasAccess} />
            {index === 0 && !hasAccess && <NoAccessDisclaimer />}
          </div>
        ))}

        <Title>Resposta</Title>
        <RenderContent text={exercise.answer} hasAccess={hasAccess} />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 960px;
  max-width: 95%;
  margin: auto;
  padding: 0 20px 30px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
`;

const Title = styled.h1`
  color: ${colors.aiColor};
  font-size: 1.5em;
  font-family: 'Droid Serif', serif;
  font-weight: bold;
  margin-top: 40px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-top: 30px;
`;

const RenderContent = styled(MathpixMarkdownContent)`
  margin: 0;
  margin-top: 30px;

  ${props =>
    !props.hasAccess &&
    `
    filter: blur(5px);
    pointer-events: none;
  `}
`;
