import React, { useMemo } from 'react';

import { LoginDialog, NoAccessDialog } from 'modules/shared/dialogs';
import { useRenderDialog } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

export function useRenderSearchedExercisePageDialog({ hasAccess }) {
  const { user } = useUserContext();

  const dialogs = useMemo(() => {
    return [
      {
        condition: () => !user,
        render: () => <LoginDialog />,
      },
      {
        condition: () => !hasAccess,
        render: () => <NoAccessDialog />,
      },
    ];
  }, [user, hasAccess]);

  useRenderDialog(dialogs);
}
