import React, { useCallback, useEffect } from 'react';

import { styled } from 'styles';
import { MdClose } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import useAutommaticalyOpen from '../hooks/useAutommaticalyOpen';
import UploadDialogProvider, {
  useUploadDialogContext,
} from '../providers/UploadDialogProvider';
import useStepNavigation from '../hooks/useStepNavigation';

/*
  Params doc:
  defaultProfessor: { id, name }
  defaultTeachable: { id, type }
  initialStep: number | null
*/
export function UploadBookListDialog({
  origin,
  onRender = () => {},
  defaultThemeId = null,
  defaultTeachable = null,
  defaultProfessor = null,
  defaultExam = null,
  utms = {},
  initialStep = 1,
}) {
  const { removeQueryParamOnOpenDialog } = useAutommaticalyOpen();

  useEffect(() => {
    removeQueryParamOnOpenDialog();
  }, [removeQueryParamOnOpenDialog]);

  useEffect(() => {
    onRender();
  }, [onRender]);

  return (
    <UploadDialogProvider
      defaultThemeId={defaultThemeId}
      defaultTeachable={defaultTeachable}
      defaultProfessor={defaultProfessor}
      defaultExam={defaultExam}
      utms={utms}
    >
      <UploadDialogContent origin={origin} initialStep={initialStep} />
    </UploadDialogProvider>
  );
}

function UploadDialogContent({ origin, initialStep = 1 }) {
  const { getComponentByStep } = useStepNavigation(initialStep);
  const { statementList, step } = useUploadDialogContext();
  const { trackCloseDialog } = useMvpBooksTracker();
  const { unsetDialog } = useDialogContext();

  const closeDialog = useCallback(() => {
    let screenByStep;
    if (!statementList) {
      screenByStep = {
        1: 'Formato Lista',
        2: 'Formulário',
        3: 'Seletor de Livros',
        4: 'Enviar Lista',
        5: 'Obrigado',
        6: 'Input Manual',
      };
    } else {
      screenByStep = {
        1: 'Formato Lista',
        2: 'Formulário',
        3: 'Enviar Lista Enunciado',
        4: 'Obrigado Lista com Enunciado',
      };
    }

    trackCloseDialog(screenByStep[step]);
    unsetDialog();
  }, [statementList, trackCloseDialog, step, unsetDialog]);

  return (
    <Container onClick={closeDialog}>
      <CloseIcon className="close-icon" />
      <Content onClick={e => e.stopPropagation()}>
        {getComponentByStep(origin)}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 650px;
  min-height: 150px;
  background: #fff;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const CloseIcon = styled(MdClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: #999;
  cursor: pointer;

  &:hover {
    scale: 1.1;
  }
`;
