import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import uploadIconPath from './assets/upload-icon.svg';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { media } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';

export default function ListUploader() {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();
  const { currentExerciseId } = useBookEditionContext();

  const utms = useMemo(() => {
    return {
      utmSource: 'site_upload',
      utmMedium: 'seo_question_trial',
      utmContent: 'pag_exercicio_livro',
    };
  }, []);

  const onClick = useCallback(() => {
    trackEvent('Trial de Questão: Interagiu CTA Listas', {
      action: 'Clicou para enviar arquivo',
      contentId: currentExerciseId,
      contentType: 'BookExercise',
      contentUrl: window.location.href,
    });

    setDialog(
      <UploadBookListDialog
        origin="Exercicios de Livro"
        utms={utms}
        initialStep={2}
      />
    );
  }, [setDialog, utms, trackEvent, currentExerciseId]);

  return (
    <Container>
      <DropZone onClick={onClick}>
        <img src={uploadIconPath} alt="Upload" />
        <p>
          Envie a <strong>foto</strong> ou <strong>pdf</strong> da{' '}
          <strong>questão</strong> ou até da sua{' '}
          <strong>lista de exercícios</strong> inteira!
        </p>
      </DropZone>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  color: #fff;
  background: #14b9a6;
  border-radius: 10px;
  padding: 15px;
  flex: 1;

  @media ${media.mobile} {
    height: 180px;
  }
`;

const DropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 2px dashed #00786a9c;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    border-color: transparent;
    background: rgba(0, 0, 0, 0.2);
  }

  img {
    width: 40px;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    width: 280px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }

  strong {
    font-weight: 700;
  }
`;
