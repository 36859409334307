import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import { useUserContext } from 'providers/UserProvider';
import { useTestAB, useTracker } from 'modules/shared/hooks';
import { useBookExercisePermission } from '../hooks/useBookExercisePermission';
import { useCookies } from 'modules/shared/hooks/useCookies';

import {
  useRAPostRequest,
  useRAQueryImmutable,
} from 'modules/shared/hooks/requests';
import dayjs from 'dayjs';

export const SEOQuestionTrialContext = createContext(null);

const SEOQuestionTrialProvider = props => {
  const { user } = useUserContext();
  const { getVariantFromTestAB } = useTestAB();
  const { currentExerciseId, edition } = useBookEditionContext();
  const { canViewBookExercise } = useBookExercisePermission(edition);

  const [loading, setLoading] = useState(true);
  const [participant, setParticipant] = useState(null);
  const [isUserParticipating, setIsUserParticipating] = useState(false);
  const [variant, setVariant] = useState(null);
  const [userHasCredits, setUserHasCredits] = useState(false);
  const [usedCredits, setUsedCredits] = useState([]);

  const [cookies, _, removeCookie] = useCookies([
    'seo_last_page',
    'seo_question_trial_forced_variant',
  ]);
  const { trackEvent } = useTracker();

  const {
    data: participantData,
    mutate: mutateParticipant,
  } = useRAQueryImmutable('/campaigns/seo_question_trial_ab_test/participant');

  const createParticipant = useRAPostRequest(
    '/campaigns/seo_question_trial_ab_test/participant'
  );

  const getSeoLastPage = useCallback(() => {
    const seoLastPage = cookies.seo_last_page || null;
    if (!seoLastPage) return null;

    const [wallType, url] = seoLastPage.split('|');
    if (!wallType || !url) return null;

    return { wallType, url };
  }, [cookies]);

  function extractContentId(seoUrl) {
    const id = seoUrl
      ?.split('-')
      ?.pop()
      ?.match(/\d+/)[0];
    if (!id) return null;

    return Number(id);
  }

  const trackIneligible = useCallback(
    reason => {
      trackEvent('Trial de Questão: Verificou elegibilidade', {
        result: 'Não elegível a entrar no teste',
        reason,
        contentId: currentExerciseId,
        contentType: 'BookExercise',
        contentUrl: window.location.href,
      });

      return false;
    },
    [trackEvent, currentExerciseId]
  );

  const hasImmediatellySignedInFromSEOBookExercise = useCallback(() => {
    const userSignInDate = dayjs(user.currentSessionLoginAt);
    const isRecentSignIn = dayjs().diff(userSignInDate, 'minutes') <= 5;
    if (!isRecentSignIn)
      return trackIneligible('Login atual foi há mais de 5min');

    const seoLastPage = getSeoLastPage();
    if (!seoLastPage) return trackIneligible('Não possui cookie do SEO');

    const { wallType, url } = seoLastPage;
    const wasInSEOBookExercise = wallType === 'Questão de Livro';
    if (!wasInSEOBookExercise)
      return trackIneligible('Última parede não foi de questão de livro');

    const seoLastPageExerciseId = extractContentId(url);
    if (!seoLastPageExerciseId)
      return trackIneligible(
        'Falha ao extrair o id do BookExercise da URL da parede'
      );

    const isEligibleExercise = seoLastPageExerciseId % 5 !== 0;
    if (!isEligibleExercise)
      return trackIneligible(
        'Exercício é do grupo controle do SEO (id múltiplo de 5)'
      );

    const isCurrentExercise = seoLastPageExerciseId === currentExerciseId;
    if (!isCurrentExercise)
      return trackIneligible(
        'Exercício atual não bate com o id da URL da parede'
      );

    return true;
  }, [getSeoLastPage, trackIneligible, currentExerciseId]);

  const isFromAllowedUniversity = useCallback(() => {
    const university = user?.university;
    if (!university) return false;

    const isFromOtherUniversity = university.name
      ?.toLowerCase()
      .includes('outra universidade');

    const notAllowedUniversities = [508, 658, 507, 371, 657, 521, 2907]; // UFRJ, UNICAMP, UFF, UFPE, USP, PUCRIO, NFF
    const isFromNotAllowedUniversity = notAllowedUniversities.includes(
      university.id
    );

    const isPublic = university.institutionType === 'public_university';

    return isPublic && !isFromOtherUniversity && !isFromNotAllowedUniversity;
  }, [user]);

  const isEligibleToEnterSEOQuestionTrialABTest = useCallback(() => {
    if (!currentExerciseId || currentExerciseId === -1 || !user) return;

    const eligibleUniversity = isFromAllowedUniversity();
    if (!eligibleUniversity)
      return trackIneligible('Universidade não é elegível ao teste');

    const userHasAccess = user.hasAccess;
    if (userHasAccess)
      return trackIneligible('Usuário já tem assinatura ou trial vigentes');

    const isFormerSubscriber = user.formerSubscriber;
    if (isFormerSubscriber)
      return trackIneligible('Usuário já foi assinante no passado');

    const questionIsAlreadyFree = canViewBookExercise(currentExerciseId);
    if (questionIsAlreadyFree)
      return trackIneligible('Questão ou livro já são grátis');

    const isSEORecentSignIn = hasImmediatellySignedInFromSEOBookExercise();
    return isSEORecentSignIn;
  }, [
    currentExerciseId,
    user,
    hasImmediatellySignedInFromSEOBookExercise,
    canViewBookExercise,
    trackIneligible,
    isFromAllowedUniversity,
  ]);

  const userIsInTestVersion = useMemo(() => {
    return isUserParticipating && variant === 'trial';
  }, [isUserParticipating, variant]);

  const hasQuestionTrial = useCallback(
    exerciseId => {
      return !!usedCredits.find(
        credit =>
          credit.contentId === exerciseId &&
          credit.contentType === 'BookExercise'
      );
    },
    [usedCredits]
  );

  const getVariant = useCallback(() => {
    const forcedVariant = cookies.seo_question_trial_forced_variant;
    if (forcedVariant) {
      removeCookie('seo_question_trial_forced_variant');
      return forcedVariant;
    }

    return getVariantFromTestAB('seo_question_trial');
  }, [cookies, getVariantFromTestAB, removeCookie]);

  useEffect(() => {
    if (participantData === undefined) return;
    if (!loading) return;

    const data = participantData.participant;

    if (data) {
      setParticipant(data);
      setIsUserParticipating(true);
      setVariant(data.variant);
      setUserHasCredits(data.availableQuestionCredits > 0);
      setUsedCredits(data.usedCredits);
      setLoading(false);
    } else {
      if (currentExerciseId === null || currentExerciseId === -1) {
        setLoading(false);
        return;
      }

      const isEligibleToParticipate = isEligibleToEnterSEOQuestionTrialABTest();

      if (isEligibleToParticipate) {
        trackEvent('Trial de Questão: Verificou elegibilidade', {
          result: 'Elegível a entrar no teste',
          contentType: 'BookExercise',
          contentId: currentExerciseId,
          contentUrl: window.location.href,
        });

        const params = {
          variant: getVariant(),
          contentType: 'BookExercise',
          contentId: currentExerciseId,
          contentUrl: getSeoLastPage()?.url,
        };

        createParticipant(params).then(() => mutateParticipant());
      } else {
        setLoading(false);
      }
    }
  }, [
    participantData,
    isEligibleToEnterSEOQuestionTrialABTest,
    createParticipant,
    mutateParticipant,
    currentExerciseId,
    getSeoLastPage,
    loading,
    trackEvent,
    getVariant,
  ]);

  useEffect(() => {
    if (!participant || !currentExerciseId || currentExerciseId === -1) return;

    trackEvent('Trial de Questão: Visualizou Questão', {
      contentId: currentExerciseId,
      contentType: 'BookExercise',
      contentUrl: window.location.href,
      variant: participant.variant,
      questionIsOnTrial: hasQuestionTrial(currentExerciseId),
      questionIsBlocked:
        !hasQuestionTrial(currentExerciseId) &&
        !canViewBookExercise(currentExerciseId),
    });
  }, [
    currentExerciseId,
    participant,
    trackEvent,
    canViewBookExercise,
    hasQuestionTrial,
  ]);

  return (
    <SEOQuestionTrialContext.Provider
      value={{
        loading,
        isUserParticipating,
        variant,
        userHasCredits,
        userIsInTestVersion,
        usedCredits,
        participant,
        hasQuestionTrial,
      }}
      {...props}
    />
  );
};

export const useSEOQuestionTrialContext = () =>
  useContext(SEOQuestionTrialContext);

export default SEOQuestionTrialProvider;
