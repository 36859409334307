import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import BookExerciseSelects from './BookExerciseSelects';
import { Link } from 'react-router-dom';
import { media } from 'styles';
import { useTracker } from 'modules/shared/hooks';

export default function BookSelector({ onChange }) {
  const { edition, currentExerciseId } = useBookEditionContext();
  const { trackEvent } = useTracker();

  const trackOtherBooksClick = useCallback(() => {
    trackEvent('Trial de Questão: Interagiu CTA Livros', {
      action: 'Clicou pra ver outros livros',
      contentId: currentExerciseId,
      contentType: 'BookExercise',
      contentUrl: window.location.href,
    });
  }, [trackEvent, currentExerciseId]);

  return (
    <Container>
      <BookInfoContainer>
        <CoverContainer>
          <img src={edition.book.imagePath} />
          <SolvedPercentage>
            {edition.solvedPercentage}% Resolvido
          </SolvedPercentage>
        </CoverContainer>
        <ControlsContainer>
          <BookName>{edition.amplitudeName}</BookName>
          <BookExerciseSelects onChange={onChange} />
        </ControlsContainer>
      </BookInfoContainer>
      <OtherBooksLink to="/livros-resolvidos" onClick={trackOtherBooksClick}>
        Ver todos os livros 100% resolvidos
      </OtherBooksLink>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  text-align: left;
  color: #fff;
  background: #14b9a6;
  border-radius: 10px;
  padding: 15px;

  @media ${media.mobile} {
    padding: 20px 10px;
  }
`;

const BookInfoContainer = styled.div`
  display: flex;
  gap: 15px;

  @media ${media.mobile} {
    gap: 10px;
  }
`;

const CoverContainer = styled.div`
  position: relative;

  img {
    height: 150px;
    border-radius: 10px;

    @media ${media.mobile} {
      height: 100px;
      border-radius: 5px;
    }
  }
`;

const ControlsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BookName = styled.p`
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;

  @media ${media.mobile} {
    font-size: 18px;
  }
`;

const OtherBooksLink = styled(Link)`
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
`;

const SolvedPercentage = styled.p`
  background: white;
  font-size: 11px;
  color: #14b9a6;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 3px 6px 3px 4px;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  bottom: 20px;
  line-height: 11px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);

  @media ${media.mobile} {
    display: none;
  }
`;
