import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, Error } from 'modules/shared/components/generic';
import {
  BookHeader,
  BookInfo,
  ProfessorsLists,
  ScrollToTopButton,
} from 'modules/books/components';
import { Forum } from 'modules/forum/components';
import ForumCTA from 'modules/forum/components/generic/ForumCTA';
import ForumProvider from 'providers/ForumProvider';
import BookEditionProvider from '../providers/BookEditionProvider';
import ContentContainer from '../components/ContentContainer';
import { useGTM } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { MathJaxWithLatexProvider } from 'providers';
import SEOQuestionTrialProvider from '../providers/SEOQuestionTrialProvider';
import SEOQuestionTrialHeader from '../components/seoQuestionTrial/SEOQuestionTrialHeader';
import { useSEOQuestionTrialContext } from '../providers/SEOQuestionTrialProvider';

export default function BookEditionPage() {
  const { exerciseId, editionId } = useParams();
  const { addVariableToGTMDataLayer } = useGTM();
  const { user } = useUserContext();
  const {
    isBannerEligibleInBookProfessorsListsSection,
  } = useMvpBooksEligible();

  const {
    data: edition,
    loading: loadingEdition,
    error: errorEdition,
  } = useContentQueryImmutable(`/books/bookEdition/${editionId}`);

  const trackBookEditionInGTM = useCallback(() => {
    addVariableToGTMDataLayer({
      contentBookName: edition.amplitudeName,
      contentBookModule: edition.book?.volume,
      contentMateria: edition.book?.theme?.name,
      contentType: 'Livro',
    });
  }, [edition, addVariableToGTMDataLayer]);

  const canNotRenderProfessorsLists = useMemo(() => {
    const canShowUploadBanner = isBannerEligibleInBookProfessorsListsSection();

    return !user || !canShowUploadBanner;
  }, [isBannerEligibleInBookProfessorsListsSection, user]);

  const shouldShowBookInfoInline = useMemo(() => {
    return !canNotRenderProfessorsLists;
  }, [canNotRenderProfessorsLists]);

  useEffect(() => {
    if (edition) {
      trackBookEditionInGTM();
    }
  }, [edition, trackBookEditionInGTM]);

  if (errorEdition) return <Error />;
  if (loadingEdition) return <Loading />;

  if (!edition) return null;

  return (
    <MathJaxWithLatexProvider>
      <BookEditionProvider edition={edition}>
        <SEOQuestionTrialProvider>
          <PageContent
            canNotRenderProfessorsLists={canNotRenderProfessorsLists}
            shouldShowBookInfoInline={shouldShowBookInfoInline}
            exerciseId={exerciseId}
          />
        </SEOQuestionTrialProvider>
      </BookEditionProvider>
    </MathJaxWithLatexProvider>
  );
}

function PageContent({
  canNotRenderProfessorsLists,
  shouldShowBookInfoInline,
  exerciseId,
}) {
  const { loading, userIsInTestVersion } = useSEOQuestionTrialContext();
  if (loading) return <Loading />;

  const isOnSEOQuestionTrial = userIsInTestVersion;

  return (
    <ForumProvider>
      <ScrollToTopButton />
      {!isOnSEOQuestionTrial && (
        <>
          <BookHeader />
          {canNotRenderProfessorsLists ? <BookInfo /> : <ProfessorsLists />}
        </>
      )}
      {isOnSEOQuestionTrial && <SEOQuestionTrialHeader />}
      <ContentContainer
        shouldShowBookInfoInline={shouldShowBookInfoInline}
        isOnSEOQuestionTrial={isOnSEOQuestionTrial}
      />
      {!isOnSEOQuestionTrial && (
        <>
          <ForumCTA />
          {exerciseId && <Forum />}
        </>
      )}
    </ForumProvider>
  );
}
