import React, { useCallback, useEffect } from 'react';
import {
  ChooseBookStep,
  ChooseListTypeStep,
  FifthStep,
  FinalStep,
  FourthStep,
  ListInfoStep,
  LoadingStep,
  ManualInput,
  UploadListWithStatementStep,
} from '../components';
import SuccessListWithStatementStep from '../components/SuccessListWithStatementStep';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';

export default function useStepNavigation(initialStep = 1) {
  const { step, setStep, loading, statementList } = useUploadDialogContext();

  const stepToRender = step !== 1 ? step : initialStep;

  useEffect(() => {
    if (initialStep !== 1) {
      setStep(initialStep);
    }
  }, [initialStep, setStep]);

  const getComponentByStep = useCallback(
    origin => {
      if (stepToRender === 1) return <ChooseListTypeStep origin={origin} />;
      if (stepToRender === 2) return <ListInfoStep origin={origin} />;

      // STEPS LISTA DE SELEÇÃO DO LIVRO
      if (!statementList) {
        if (stepToRender === 3) return <ChooseBookStep />;
        if (stepToRender === 4) return <FourthStep />;
        if (stepToRender === 5) return <FifthStep />;
        if (stepToRender === 6) return <ManualInput />;
        if (stepToRender === 7) return <FinalStep />;
      }

      // STEPS LISTA DE ENUNCIADO
      if (statementList) {
        if (stepToRender === 3) return <UploadListWithStatementStep />;
        if (stepToRender === 4) return <SuccessListWithStatementStep />;
      }

      if (stepToRender === 'loading') {
        return <LoadingStep loading={loading} />;
      }
    },
    [loading, statementList, stepToRender]
  );

  return {
    getComponentByStep,
  };
}
